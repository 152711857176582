import React from 'react'
import {Trans, useTranslation} from 'gatsby-plugin-react-i18next'
import { Layout, Stack, Main, PreFooter } from '@layout'
import PageTitle from '@components/PageTitle'
import Pagination from '@components/Pagination'
import CardList from '@components/CardList'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'

const PageCollection = ({ data: { posts, collectionInfo }, ...props }) => {
  const { t } = useTranslation()
  return (
    <Layout {...props}>
      <Seo title={t(collectionInfo.name)} description={t(collectionInfo.description)} />
      <Divider />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          header={collectionInfo.name}
          subheader={collectionInfo.title}
          running={collectionInfo.description}
        />
      </Stack>
      <Divider />
      <Stack>
        <Main>
          {posts.nodes && (
            <CardList
              omitFooter
              nodes={posts.nodes}
              variant={['horizontal-cover-hero']}
              columns={[1, 2, 3, 3]}
              omitCategory={
                props.pageContext &&
                props.pageContext.collectionType === 'category'
              }
            />
          )}
        </Main>
      </Stack>
      <Divider />
      <PreFooter>
        <Pagination {...posts.pageInfo} {...collectionInfo} />
      </PreFooter>
    </Layout>
  )
}
export default PageCollection
